.lu-wrapper {
  position: relative;

  > .lu {
    clear: both;
    position: absolute;
    left: 0;
    right: 0;
    top: 1px;
    bottom: 0;
  }
}
